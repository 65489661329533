import React from 'react'
import I18n from '../utils/I18n'
import partner1 from '../assets/images/CGA.png' // Tell Webpack this JS file uses this image
import partner2 from '../assets/images/Fortinet.png' // Tell Webpack this JS file uses this image

const CybersecurityPartners = ({lang}) => {

  const i18n = new I18n({lang})

  return (
    //  the return() to put your default HTML into the DOM
    <div>
      <div className="slds-text-align_center">{i18n.text('cybersecurity_partners_title')}</div>
      <div className="slds-container--large slds-container--center">
        <div className="slds-grid slds-wrap slds-grid_align-center slds-grid_pull-padded-large slds-grid_vertical-stretch">
          <div className="slds-card tds-card wef-card-partner slds-m-around--medium slds-align--absolute-center">
            <a
              className="slds-grid slds-text-align_center slds-grid_vertical-align-center slds-p-vertical_small slds-p-horizontal_medium"
              style={{height: '100%'}}
              href="https://www.globalcyberalliance.org/who-we-are/"
              target="_blank">
              <img src={partner1}/>
            </a>
          </div>
          <div className="slds-card tds-card wef-card-partner slds-m-around--medium slds-align--absolute-center">
            <a
              className="slds-grid slds-text-align_center slds-grid_vertical-align-center slds-p-vertical_small slds-p-horizontal_medium"
              style={{height: '100%'}}
              href="https://training.fortinet.com/local/staticpage/view.php?page=nse"
              target="_blank">
              <img src={partner2}/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CybersecurityPartners
