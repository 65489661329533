import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import langsObj from '../utils/Langs'
import '../assets/stylesheets/newStyles.scss'

export const LWCLayoutQuery = graphql`
  query LWCLayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`

function OnReady() {
  const isBrowser = typeof window !== "undefined"
  
  if(isBrowser) {
    //window.jQuery = window.$ = jQuery
    
    function fixTdsContentTiles() { // Handler for .ready() called.
      const learnSection = document.getElementById('learnSection')
      if(learnSection) {
        const tdsContentTiles = learnSection.querySelectorAll('tds-content-tile')

        customElements.whenDefined("tds-content-tile").then(() => {
        
          tdsContentTiles.forEach((element, id) => {
            const trailmix = element.dataset
            
            element.summary = {
              color: trailmix.color,
              id: id,
              image: trailmix.image,
              path: trailmix.path,
              title: trailmix.title,
              description: trailmix.description,
              type: "trailmix",
              estimatedTime: trailmix.estimatedTime,
              points: trailmix.points
            }
          })
        })
      }
    }

    fixTdsContentTiles()
  }
}

const LWCLayout = ({ children, lang, slug }) => {

  const langCode = langsObj.getLangsCodes()
  const isoCode = langCode[lang] ? langCode[lang] : 'en';

  return (
    <StaticQuery
      query={LWCLayoutQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            <link rel="canonical" href={`https://trailhead.salesforce.com/career-path/${slug}`} />

            <meta http-equiv="content-language" content={isoCode} />
            <link href={`https://trailhead.salesforce.com/career-path/${slug}`} hreflang="x-default" rel="alternate"></link>

            {
              Object.keys(langCode).map(key => {
                return (<link href={`https://trailhead.salesforce.com/${key}/career-path/${slug}`} hreflang={langCode[key]} rel="alternate"></link>)
              })
            }
            
            {
              /*
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://trailhead.salesforce.com/career-path/marketer" />
              <meta property="og:title" content={siteMetadata.title} />
              <meta property="og:description" content={siteMetadata.description} />
              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:title" content={siteMetadata.title} />
              <meta property="twitter:description" content={siteMetadata.description} />
              */
            }
            
            <script type='text/javascript' src="https://developer.salesforce.com/resources2/dscJobsComponent/dscJobsComponent.js?v=4"></script>
            <script src={`${siteMetadata.tdsPath}/scripts/th-element-loader.js`}></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />
          </Helmet>

          <div id="career-site" className="career-site contentArea">
            {children}
            {OnReady()}
          </div>
        </>
      )}
    />
  )
}

LWCLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default LWCLayout
